import { BrowserRouter as Router, Switch, Redirect, Route } from "react-router-dom";
import React, { Suspense } from "react";
import ProtectedRoute  from './helpers/authHelper';
import { adminRoot } from "./constants/ServiceConfig";
import { AuthProvider } from "./contexts/AuthContext"

const ViewApp = React.lazy(() =>
  import('./screens/App')
);

const LoginPage = React.lazy(() =>
  import('./screens/Login')
);


const App = () => {
  return (
    <div className=''>
      <Suspense fallback={<div className="loading" />}>
        <Router>
          <AuthProvider>
            <Switch>
              <ProtectedRoute
                path={adminRoot}
                component={ViewApp}
              />
              <Route
                path="/login"
                render={(props) => <LoginPage {...props} />}
              />
              <Redirect exact from="/" to={adminRoot} />
            </Switch>
          </AuthProvider>
        </Router>
      </Suspense>
    </div>
  )
}


export default App;
